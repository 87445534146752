<template>
  <section class="props">
    <div class="container">
      <div class="props__body">
        <div class="props__title">
          <h1>Реквизиты</h1>
          <tk-button kind="props-btn" class="props__btn" @click="downloadDetails">
            Скачать PDF
          </tk-button>
        </div>
        <div class="props__items">
          <div class="props__left">
            <div class="props__item">
              <p>Название</p>
              <div>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ТЕРЕМОК»</div>
            </div>
            <div class="props__item">
              <p>Юр. адрес</p>
              <div>ул. Весенняя, д. 1, корп./ст. 5, кв./оф. 5, Московская область</div>
            </div>
            <div class="props__item">
              <p>ИНН</p>
              <div>5032337180</div>
            </div>
            <div class="props__item">
              <p>КПП</p>
              <div>503201001</div>
            </div>
          </div>
          <div class="props__right">
            <div class="props__item">
              <p>Банк</p>
              <div>АО “АЛЬФА-БАНК”</div>
            </div>
            <div class="props__item">
              <p>Номер счета</p>
              <div>40702810402270003168</div>
            </div>
            <div class="props__item">
              <p>БИК</p>
              <div>044525593</div>
            </div>
            <div class="props__item">
              <p>Кор. счет</p>
              <div>30101810200000000593</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TkButton from "@/components/global/tkButton";

export default {
  name: "OrganisationDetails",
  components: { TkButton },
  methods: {
    downloadDetails() {
      window.open('https://teremok.storage.yandexcloud.net/ooo.pdf', '_blank')
    }
  }
}
</script>

<style lang="scss">
.props{
  margin-bottom: 50px;
  &__body{}
  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    h1{
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      margin: 0;
    }
  }
  &__btn{
    height: 40px;
    padding: 8px 16px;
    border-radius: 12px;
  }
  &__items{
    display: grid;
    grid-template-columns: 10fr 6fr;
    gap: 24px;
    & > div{
      padding: 24px;
      border: 1px solid #EDEDEF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  &__item{
    p{
      line-height: 26px;
      color: #626170;
      margin: 0 0 5px 0;
    }
    div{
      line-height: 24px;
      color: #16142B;
    }
  }
  @media screen and (max-width: 991px) {
    &__items{
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  @media screen and (max-width: 479px) {
    &__title{
      margin: 25px 0;
      h1{
        font-size: 22px;
        line-height: 32px;
      }
    }
  }
}
</style>
